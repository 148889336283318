
import { defineComponent } from "vue";
import { faFile , faChartBar ,faMicrochip, faTablet , faSnowflake } from "@fortawesome/free-solid-svg-icons";

export default defineComponent({
  name: "Home" ,
  data (){
      
      return {
          showDashboardEdit : false ,
          showViews : false ,
          faFile : faFile ,
           faMicrochip: faMicrochip,
           faSnowflake: faSnowflake,
           faTablet: faTablet,
           faChartBar : faChartBar
      }
  },
   mounted : function (this:any){
       
          this.$emit('show-header');  
          this. showViews = this.$parent.$parent.isLoggedin;      
          
          const isShowDashboardEdit =  ( this.$parent.$parent.dashboard.editmode ) ? true: false;
          this. showDashboardEdit = isShowDashboardEdit ; 

  },
  methods : {
     async addMenu(this:any , menu : Array<any> ){
        
        try{
            var option:any = {}  ;
            option.method = "POST";
            option.body = JSON.stringify( menu );
            let res = await fetch (process.env.VUE_APP_API_URL + 'AddMenu'+process.env.VUE_APP_API_CODE ,
            option
            );      
           
            let data = await res.json();  
           if ( !data.error ){
                this.$emit('show-flash',{ "message":"メニューを設定しました。","type": "success"});     
                 
                 setTimeout( ()=>{
                      window.location.href = '/pwa/home/';      
                 }, 5000);

           }    
            
          } catch (e:any){
         
              console.log(e);
               this.$emit('show-flash',{ "message":"設定に失敗しました。","type": "warring"});  
         }
        
        // https://iotcore-inte-api.azurewebsites.net/api/AddMenu?code=fkcoPR3MPBwkBi_LdiIBsr9QyO1wr39X-_ZZNkrwhpetAzFuyJNmUQ==
    }   
  }
});
